<template>
  <v-app>
    <v-row>
      <div class="col-sm-3">
        <h4 class="text-center p-3">{{ $t('reports.service_type') }}</h4>
        <div class="btn ml-2 btn-warning w-100 mb-2 text-left" v-for="item in servicesType" :key="item.id" @click="setServicesReports(todayStarts(), todayEnds(), item.id, item.name)">
          <b>{{ item.name }}</b> - {{ item.count }} {{ $t('reports.services') }}
        </div>
      </div>
      <div class="col-sm-9">
        <v-card>
          <v-card-title>
            <div class="w-100 mb-1">{{ selectedCategoryName }}</div>
            <b-button variant="success" @click="getServicesReports(todayStarts(), todayEnds(), selectedCategory)">{{ $t('reports.today') }}</b-button>
            <b-button variant="success" class="ml-1" @click="getServicesReports(yesterdayStarts(), yesterdayEnds(), selectedCategory)">{{ $t('reports.yesterday') }}</b-button>
            <b-button variant="success" class="ml-1" @click="getServicesReports(sevenDaysAgoStarts(), yesterdayEnds(), selectedCategory)">{{ $t('reports.last_7_days') }}</b-button>
            <b-button variant="success" class="ml-1" @click="getServicesReports(thirtyDaysAgoStarts(), yesterdayEnds(), selectedCategory)">{{ $t('reports.last_30_days') }}</b-button>
            <b-button variant="success" class="ml-1" @click="getServicesReports(firstDayOfMonth(), todayEnds(), selectedCategory)">{{ $t('reports.current_month') }}</b-button>
            <b-button variant="success" class="ml-1" @click="getServicesReports(firstDayOfPreviousMonth(), lastDayOfPreviousMonth(), selectedCategory)">{{ $t('reports.previous_month') }}</b-button>
            <flat-pickr @input="changeDate" class="btn ml-1 btn-primary text-white flatpicker-reports" v-model="flatpicker" :config="basicMinTimeConfig" :placeholder="fromDate + ' - ' + toDate"></flat-pickr>
            <v-spacer></v-spacer>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('reports.search')"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="reports"
            :search="search"
            :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
          >
            <template v-slot:foot>
              <br>
              <div class="totalValue">{{ $t('reports.total') }}: {{ totalValue }}</div>
              <br>&nbsp;
            </template>
          </v-data-table>
        </v-card>
        <download-excel :footer="'Total:' + totalValue" type="csv" :name="$t('reports.services_report') + ': ' + selectedCategoryName +' ' + fromDate + ' - ' + toDate + '.csv'" class="btn ml-2 btn-success download-excel" :data="jsonReports">
          Export as CSV
        </download-excel>
      </div>
    </v-row>
  </v-app>
</template>

<script>
import { core } from '@/config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import downloadExcel from 'vue-json-excel'

export default {
  components: {
    downloadExcel
  },
  data () {
    return {
      selectedCategory: '',
      selectedCategoryName: '',
      jsonReports: [],
      servicesType: '',
      flatpicker: '',
      basicMinTimeConfig: {
        mode: 'range',
        disable: [
          {
            from: new Date(),
            to: new Date('2050-01-01')
          }
        ]
      },
      fromDate: '',
      toDate: '',
      search: '',
      totalValue: '',
      headers: [
        { text: 'Service', align: 'start', sortable: true, value: 'name' },
        { text: 'Duration', value: 'duration' },
        { text: 'Price', value: 'price' },
        { text: 'Bookings', value: 'bookings' },
        { text: 'Total', value: 'total', align: 'right' }
      ],
      reports: [],
      apiToken: localStorage.getItem('api_token') || ''
    }
  },
  mounted () {
    core.index()
    this.getServicesType()
  },
  methods: {
    changeDate (ent) {
      if (ent.includes('to')) {
        const theDates = ent.split(' to ')
        const current = new Date(theDates[0])
        const next = new Date(theDates[1])
        this.getServicesReports(current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate() + ' 00:00:00', next.getFullYear() + '-' + next.getMonth() + '-' + next.getDate() + ' 00:00:00', this.selectedCategory)
      }
    },
    firstDayOfPreviousMonth () {
      const current = new Date()
      current.setMonth(current.getMonth() - 1)
      const date = current.getFullYear() + '-' + current.getMonth() + '-01'
      const time = '00:00:00'
      return date + ' ' + time
    },
    lastDayOfPreviousMonth () {
      const current = new Date()
      current.setDate(0)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    firstDayOfMonth () {
      const current = new Date()
      const date = current.getFullYear() + '-' + current.getMonth() + '-01'
      const time = '00:00:00'
      return date + ' ' + time
    },
    thirtyDaysAgoStarts () {
      const current = new Date()
      current.setDate(current.getDate() - 31)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    sevenDaysAgoStarts () {
      const current = new Date()
      current.setDate(current.getDate() - 8)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    yesterdayStarts () {
      const current = new Date()
      current.setDate(current.getDate() - 1)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    yesterdayEnds () {
      const current = new Date()
      current.setDate(current.getDate() - 1)
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '23:59:59'
      return date + ' ' + time
    },
    todayStarts () {
      const current = new Date()
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = '00:00:00'
      return date + ' ' + time
    },
    todayEnds () {
      const current = new Date()
      const date = current.getFullYear() + '-' + current.getMonth() + '-' + current.getDate()
      const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds()
      return date + ' ' + time
    },
    getServicesType () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SERVICES_TYPE)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.servicesType = response.data.servicesType
            this.setServicesReports(this.todayStarts(), this.todayEnds(), this.servicesType[0].id, this.servicesType[0].name)
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setServicesReports (fromDate, toDate, category, name) {
      this.selectedCategory = category
      this.selectedCategoryName = name
      this.getServicesReports(fromDate, toDate, this.selectedCategory)
    },
    getServicesReports (fromDate, toDate, category) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const dates = {
        from: fromDate,
        to: toDate,
        category: category
      }
      Axios.post(API.API_SERVICES_REPORTS, dates)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.reports = response.data.message.reports
            this.totalValue = response.data.message.totalReportsValue
            this.flatpicker = ''
            this.fromDate = response.data.message.fromDate
            this.toDate = response.data.message.toDate
            this.jsonReports = response.data.message.reportsJson
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>

<style type="scss">
.flatpicker-reports{
  min-width: 250px;
}
.text-white::-webkit-input-placeholder {
  color: white;
}
.totalValue{
  position: absolute;
  text-align: right;
  width: 100%;
  padding-right: 16px;
  font-weight: bold;
}
.download-excel{
  margin-top: 10px;
  width: 160px;
  display: inline;
}
</style>
